import { useEffect, useRef, useState } from "react";
import { useSendVoice } from "../../../hooks/voice-hook";
import { SvgMicrophone } from "../../../icon/SvgMicrophone"
//@ts-ignore
import MicRecorder from 'mic-recorder-to-mp3';
import { SvgSend } from "../../../icon/SvgSend";
import { SvgClose } from "../../../icon/SvgClose";
import { methodHandelScrollBoxToBottom } from "../../../lib/layout-lib";

export const ButtonSection = ({ isRecording, setIsRecording, setVoiceClient, setText, text, socket, voiceClient, setChat, chat , handelSendMessag }: any) => {
    const [recorder] = useState(() => new MicRecorder({ bitRate: 128 }));
    const {isPending ,  mutate } = useSendVoice(setText)


 

    const startRecording = () => {
        recorder
            .start()
            .then(() => setIsRecording(true))
            .catch((e: any) => console.error('Error starting recording:', e));
    };

    const stopRecording = () => {
        recorder
            .stop()
            .getMp3()
            .then(([buffer, blob]: any) => {
                const formData = new FormData();
                formData.append('file', blob);
                mutate(formData)
                const blobURL = URL.createObjectURL(blob);
                setVoiceClient(blobURL)
                setIsRecording(false);
            })
            .catch((e: any) => console.error('Error stopping recording:', e));
    };

    const handleMouseDown = () => {
        startRecording()
    };

    const handleMouseUp = () => {
        stopRecording()
    };

    const handleTouchStart = () => {
        startRecording()
    };

    const handleTouchEnd = () => {
        stopRecording()
    };


    return (
        <>
            <div className="w-full h-[120px] flex items-center px-3 relative">
                <span className={`absolute  mx-auto left-0 right-0 text-center select-none text-white transition-all ${isRecording ? 'top-0 opacity-100' : 'top-2 opacity-0'}`}>Listening . . .</span>
                <span className={`absolute  mx-auto left-0 right-0 text-center select-none text-white transition-all ${isPending ? 'top-0 opacity-100' : 'top-2 opacity-0'}`}>Waiting . . .</span>
                <button
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                    className={`bg-btn-gradient w-[70px] h-[70px] rounded-full flex items-center justify-center relative border mx-auto ${isPending ? 'cursor-wait' : 'cursor-pointer'} `} disabled={isPending}>
                    <SvgMicrophone className="w-10 stroke-white" />
                    {isRecording &&
                        <>
                            <span className="bg-btn-gradient-wave"></span>
                            <span className="bg-btn-gradient-wave"></span>
                            <span className="bg-btn-gradient-wave"></span>
                        </>
                    }
                </button>
                {text && chat.length > 0 &&
                    <button onClick={()=>{setText('')}} className="absolute bg-btn-gradient w-[24px] h-[24px] rounded-full top-10 right-0 left-24 mx-auto flex items-center justify-center border border-white">
                        <SvgClose className={'w-5 fill-white'} />
                    </button>
                }
                {text && chat.length > 0 &&
                    <div className="absolute w-[180px] bg-[#2B2D31] p-2 rounded-md text-sm mx-auto -top-14 left-0 right-0 z-10">
                        <textarea className="w-full min-h-[60px] bg-transparent text-white text-sm resize-none outline-none no-scrollbar" onChange={(e) => { setText(e.target.value) }} value={text} />
                    </div>
                }
                {text &&
                    <button onClick={handelSendMessag} className="bg-btn-gradient absolute w-[40px] h-[40px] right-16 flex items-center justify-center rounded-full border">
                        <SvgSend className="fill-white w-5" />
                    </button>
                }
            </div>
        </>
    )
}