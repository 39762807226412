import { useEffect, useMemo, useRef, useState } from "react"
import { SvgPlay } from "../../../icon/SvgPlay"
import { SvgPuse } from "../../../icon/SvgPuse"
import WavesurferPlayer from '@wavesurfer/react'


export const ChatContent = ({ chat }: any) => {
  return (
    <>
      <div className="w-full h-[calc(100%-120px)]  flex flex-col px-2">
        <img src="/assets/flower.gif" className="h-[90px] mx-auto" />
        <div id='chat-container' className="w-full  h-[calc(100%-90px)] overflow-y-auto no-scrollbar flex flex-col pt-4" >
          {chat.map((item: any, index: number) =>
            <div key={index} className={`w-full  mb-4 flex ${item.type == 'client' ? 'justify-end' : 'justify-start'}`}>
              <div className="w-[60%] p-2 bg-[#2B2D31] rounded-2xl text-white">
                <div className="w-full flex items-center justify-end">
                  <span className="mr-2 text-sm">{item?.date?.slice(11, 16)}</span>
                  <img src="/assets/wave.png" className="w-[30px]" />
                </div>
                <p>
                  {item.message}
                </p>
                {item.voice &&
                  // <VoiceWave voice={item.voice} />
                  <VoiceWaveFake voice={item.voice} />
                }
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}



const VoiceWave = ({ voice }: any) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [wavesurfer, setWavesurfer] = useState<any>(null)

  const onReady = (ws: any) => {
    setWavesurfer(ws)
    setIsPlaying(false)
  }

  const onPlayPause = () => {
    wavesurfer && wavesurfer.playPause()
  }

  return (
    <>
      <div className="w-full flex items-center mt-4 mb-2 shadow-[-1px_2px_9px_-1px_rgba(153,206,255,1)] rounded-full p-2">

        <button className="w-[35px] h-[35px] rounded-full bg-btn-gradient flex items-center justify-center" onClick={onPlayPause}>
          {isPlaying ?
            <SvgPuse className="w-[15px] fill-white" />
            :
            <SvgPlay className="w-[15px]" />
          }
        </button>
        <div className="w-[calc(100%-30px)] px-2">
          <WavesurferPlayer
            height={35}
            waveColor="#fff"
            url={voice}
            barGap={4}
            barWidth={2}
            onReady={onReady}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onError={(err) => console.error('WaveSurfer Error:', err)}
          />
        </div>

      </div>
    </>
  )
}


const VoiceWaveFake = ({ voice }: any) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef: any = useRef(null);

  function getRandomNumber() {
    return Math.floor(Math.random() * (30 - 5 + 1)) + 5;
  }

  const randomHeights = useMemo(() => {
    return new Array(20).fill('').map(() => getRandomNumber());
  }, []);  // مقدار randomHeights تنها یکبار محاسبه می‌شود

  const togglePlayback = () => {
    if (isPlaying) {
      audioRef.current.pause();  // توقف پخش
    } else {
      audioRef.current.play();   // شروع پخش
    }
    setIsPlaying(!isPlaying);  // تغییر وضعیت پخش
  };

  return (
    <>
      <audio className="hidden" ref={audioRef} src={voice} onEnded={()=>{setIsPlaying(false)}} />
      <div className="w-full flex items-center mt-4 mb-2 shadow-[-1px_2px_9px_-1px_rgba(153,206,255,1)] rounded-full p-2">

        <button className="w-[35px] h-[35px] rounded-full bg-btn-gradient flex items-center justify-center" onClick={togglePlayback}>
          {isPlaying ?
            <SvgPuse className="w-[15px] fill-white" />
            :
            <SvgPlay className="w-[15px]" />
          }
        </button>
        <div className="w-[calc(100%-30px)] h-[30px] px-2 flex items-center justify-between">
          {randomHeights.map((height, index) => (
            <span key={index} className="w-[2px] rounded-sm bg-white" style={{ height: `${height}px` }}></span>
          ))}
        </div>

      </div>
    </>
  )
}
